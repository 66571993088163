import { Message, MessageBox } from "element-ui";

const MessageUtils = {
    success(text = '操作成功!') {
        Message({
            message: text,
            type: 'success',
            duration: 3 * 1000
        })
    },
    error(text = '操作失败!') {
        Message({
            message: text,
            type: 'error',
            duration: 3 * 1000
        })
    },
    info(text = '提示信息!') {
        Message({
            message: text,
            type: 'info',
            duration: 3 * 1000
        })
    },
    warning(text = '警告信息!') {
        Message({
            message: text,
            type: 'warning',
            duration: 3 * 1000
        })
    },
    confirm(text) {

        return new Promise((resolve, reject) => {
            MessageBox.confirm(text, '系统提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                resolve(true)
            }).catch(() => {
                Message.warning('已取消')
                reject(false)
            })
        }).catch(() => {
            // 可对错误进行处理
        })
    }
}

export default MessageUtils