import axios from 'axios'
import qs from 'qs'
import Cookies from "js-cookie";
import { removeUserId, removeToken } from "../utils/auth";

import store from '../store'

const service = axios.create({
    baseURL: 'http://120.78.131.1:8080/',
    // baseURL: 'http://127.0.0.1:8000/',
    // baseURL: 'http://139.9.33.123:8002/',

    timeout: 10000
})
//发送请求之前处理
service.interceptors.request.use(
    
    config => {
        if (Cookies.get("token")) {
            
            Object.assign(config.headers, { Authorization: ` ${Cookies.get("token")}` })
        }
        
        return config
    }, error => {
        return Promise.reject(error)
    })
//请求返回
service.interceptors.response.use(
    response => {

        if (response.status === 200) {

            //等于600说明token过期或者token认证失败，需要从新登陆
            if (response.data.code == 600) {
                Cookies.set("token", "")
                window.location.href = "/login";
                return response;
            } else {

                return Promise.resolve(response);
            }
        } else {
            return Promise.reject(response);
        }
    }, error => {
        if (error.response.status == 401) {
            removeToken();
            removeUserId();
            store.commit('clear')
            sessionStorage.removeItem('tabsList')
            sessionStorage.removeItem('codeList')
            window.location.href = "/login";
        }
        return Promise.reject(error.response.data)
    })

//请求方法
const http = {
    post(url, params) {
        return service.post(url, params, {
            transformRequest: [(params) => {
                return JSON.stringify(params)
            }],
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    put(url, params) {
        return service.put(url, params, {
            transformRequest: [(params) => {
                return JSON.stringify(params)
            }],
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    //parm =>  {id:10}
    // http://localhost:8089/api/user?id=10
    get(url, params) {
        return service.get(url, {
            params: params,
            paramsSerializer: (params) => {
                return qs.stringify(params)
            }
        })
    },
    //parm =>  {id:10}
    // http://localhost:8089/api/user/10
    getRestApi(url, params) {
        let _params
        if (Object.is(params, undefined || null)) {
            _params = ''
        } else {
            _params = '/'
            for (const key in params) {
                console.log(key)
                console.log(params[key])
                // eslint-disable-next-line no-prototype-builtins
                if (params.hasOwnProperty(key) && params[key] !== null && params[key] !== '') {
                    _params += `${params[key]}/`
                }
            }
            //去掉参数最后一位?
            _params = _params.substr(0, _params.length - 1)
        }
        console.log(_params)
        if (_params) {
            return service.get(`${url}${_params}`)
        } else {
            return service.get(url)
        }
    },
    //parm =>  {id:10}
    // http://localhost:8089/api/user/10
    delete(url, params) {
        let _params
        if (Object.is(params, undefined || null)) {
            _params = ''
        } else {
            _params = '/'
            for (const key in params) {
                // eslint-disable-next-line no-prototype-builtins
                if (params.hasOwnProperty(key) && params[key] !== null && params[key] !== '') {
                    _params += `${params[key]}/`
                }
            }
            //去掉参数最后一位?
            _params = _params.substr(0, _params.length - 1)
        }
        if (_params) {
            return service.delete(`${url}${_params}`).catch(err => {
                return Promise.reject(err)
            })
        } else {
            return service.delete(url).catch(err => {
                return Promise.reject(err)
            })
        }
    },
    upload(url, params) {
        return service.post(url, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    login(url, params) {
        return service.post(url, params, {
            transformRequest: [(params) => {
                return qs.stringify(params)
            }],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    getImage(url) {
        return service.post(url, null, {
            responseType: 'arraybuffer'
        })
    },
    // down(url, params) {
    //     return service.get(url, params, {
    //         responseType: 'blob'
    //     })
    // },
    down(url, params) {
        return service({
            method:'GET',
            url:url,
            params:params,
            responseType:'blob'
        }
        )
    }
}
export default http