import Cookies from 'js-cookie'
const tokenKey = 'token'
const userIdKey = 'userId'

export function setToken(params) {
    return Cookies.set(tokenKey, params)
}

export function getToken() {
    return Cookies.get(tokenKey)
}

export function removeToken() {
    return Cookies.remove(tokenKey)
}

export function setUserId(params) {
    return Cookies.set(userIdKey, params)
}

export function getUserId() {
    return Cookies.get(userIdKey)
}

export function removeUserId() {
    return Cookies.remove(userIdKey)
}




