import { getMenuList } from "../api/login"
// import { getUserId } from "../utils/auth"
export default {
    state: {
        editableTabsValue: "desktop",
        editableTabs: [
            {
                title: '首页',
                name: 'desktop'
            }
        ],
        isCollapse: false,
        menuList: [],
        routerList: [],
       
       
    },
    mutations: {
        clear(state){
            state.menuList=[]
            state.routerList=[]
            state.editableTabs= [{
                title: '首页',
                name: 'desktop'
            }]
                
            
        },
        getTabs(state) {
            let tabs = sessionStorage.getItem('tabsList')
            if (tabs) {
                state.editableTabs = JSON.parse(tabs)
            }
        },
        
        setActiveTabs(state, val) {
            state.editableTabsValue = val
        },
        setOpenOrClose(state) {
            state.isCollapse = !state.isCollapse
        },
        setTruename(state,val){
            state.truename=val
        },
        clickMenu(state, val) {
            let res = state.editableTabs.findIndex(item => item.name === val.name)
            if (res === -1) {
                let tab = {}
                tab.title = val.label
                tab.name = val.name
                state.editableTabs.push(tab)
            }
            state.editableTabsValue = val.name
            sessionStorage.setItem('tabsList', JSON.stringify(state.editableTabs))
        },
        setMenuList(state, list) {
            state.menuList = list
        },
        setRouterList(state, list) {
            state.routerList = list
        },
        dynamicRouter(state, router) {
            let oldRouter = router.options.routes

            let routerList = state.routerList

            routerList.forEach(item => {
                if (/^.+\.html$/ig.test(item.url)) {
                    console.log('进入iframe路由')
                    item.component = resolve => require([`@/views/IFrame/IFrame.vue`], resolve);
                } else {
                    // item.component = () => import(`@/views${item.url}.vue`);
                    item.component = (resolve) => require([`@/views${item.url}.vue`], resolve);
                }
                oldRouter[1].children.push(item);
            })

            //5.添加到现有路由里面
            router.addRoutes(oldRouter);

        }
    },
    actions: {
        async getMenuLists({ commit }, router) {
            // let userId = getUserId()
            // let parm = {
            //     userId: userId,
            // };
            let res = await getMenuList()
            
            let menuList = res.data.menuList
            console.log(res.data.menuList[0].children.length);
            let routerList = res.data.routerList
            sessionStorage.setItem('codeList', JSON.stringify(res.data.authList))
            // sessionStorage.setItem('codeList', res.data.authList)

            commit('setMenuList', menuList)
            commit('setRouterList', routerList)
            commit('dynamicRouter', router)
        }
    },
}