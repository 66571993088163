import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/',
        name: 'desktop',
        component: () => import('@/views/Desktop.vue')
      },
      // {
      //   path: '/departmentList',
      //   name: 'departmentList',
      //   component: () => import('@/views/system/Department/DepartmentList.vue')
      // },
      // {
      //   path: '/userList',
      //   name: 'userList',
      //   component: () => import('@/views/system/User/UserList.vue')
      // },
      // {
      //   path: '/roleList',
      //   name: 'roleList',
      //   component: () => import('@/views/system/Role/RoleList.vue')
      // },
      // {
      //   path: '/menuList',
      //   name: 'menuList',
      //   component: () => import('@/views/system/Menu/MenuList.vue')
      // },
      // {
      //   path: '/goodCategory',
      //   name: 'goodCategory',
      //   component: () => import('@/views/goods/goodsCategory/goodsCategoryList.vue')
      // },
      // {
      //   path: '/goodsBrand',
      //   name: 'goodsBrand',
      //   component: () => import('@/views/goods/goodsBrand/goodsBrandList.vue')
      // },
      // {
      //   path: '/systemCode',
      //   name: 'systemCode',
      //   component: () => import('@/views/system/config/code.vue')
      // },
      // {
      //   path: '/document',
      //   name: 'document',
      //   component: () => import('@/views/system/config/systemDocument.vue')
      // }
    ]

  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
