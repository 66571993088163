import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import 'font-awesome/css/font-awesome.min.css'
import Fragment from 'vue-fragment'
// import Print from 'vue-print-nb'
import Print from './utils/print'
import MessageUtils from '../src/utils/MessageUtils'

import echarts from 'echarts'
import { getToken, removeToken } from './utils/auth'

Vue.use(Fragment.Plugin)
Vue.prototype.$mes = MessageUtils
Vue.prototype.$echarts=echarts
Vue.config.productionTip = false
import hasPermisson from '../src/pesmisson/index'
Vue.prototype.hasPerm = hasPermisson
Vue.use(ElementUI);
Vue.use(Print);
const whiteList = ['/login']
router.beforeEach(async (to, from, next) => {
  let token = getToken()
  if (token) {
    if (to.path === "/login" || to.path === '/') {
      next({ path: '/home' })
    } else {
      let menuList = store.state.MenuStore.menuList
      if (menuList && menuList.length > 0) {
        next()
      } else {
        try {
          setTimeout(async function () {
            await store.dispatch('getMenuLists', router)
          }, 1000)
          next()
        } catch (error) {
          removeToken()
          next({ path: '/login' })
        }
      }
    }
    store.commit('getTabs')
    store.commit('setActiveTabs', to.name)
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next({ path: '/login' })
    }
  }

})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
